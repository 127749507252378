import React, { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const FormComponent = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    product: ''
  });
  const [showQRCode, setShowQRCode] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowQRCode(true);
  };

  const qrCodeValue = `${window.location.origin}/details?name=${encodeURIComponent(formData.name)}&phone=${encodeURIComponent(formData.phone)}&email=${encodeURIComponent(formData.email)}&product=${encodeURIComponent(formData.product)}`;

  return (
    <div className="container mx-auto p-4 md:-mt-4 md:ml-0 ">
      {!showQRCode ? (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className=' sm:flex  sm:ml-[70px] md:ml-0'>
            <div>
          <div>
            <label className="block text-sm font-medium md:text-[18px] text-[] " style={{color: '#FFBE2A'}}>Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 block md:w-full w-56 outline-none border border-gray-300 shadow-sm  sm:text-sm"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium md:text-[18px]" style={{color: '#FFBE2A'}}>Phone</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="mt-1 block md:w-full w-56 border outline-none border-gray-300 shadow-sm  sm:text-sm"
              required
            />
          </div>
          </div>
          <div className='landscape:ml-6 sm:ml-4'>
          <div>
            <label className="block text-sm font-medium md:text-[18px]" style={{color: '#FFBE2A'}} >Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 block md:w-full outline-none w-56 border border-gray-300  shadow-sm  sm:text-sm"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium md:text-[18px]" style={{color: '#FFBE2A'}}>Product</label>
            <input
              type="text"
              name="product"
              value={formData.product}
              onChange={handleChange}
              className="mt-1 block md:w-full outline-none w-56 border border-gray-300  shadow-sm  sm:text-sm"
              required
            />
          </div>
          </div>
          </div>
          <button
            type="submit"
            className="mt-2 px-2 sm:w-28 inline-flex justify-center  sm:ml-60 sm:py-2 lg:ml-[75px] xl:ml-[105px] 2xl:ml-[140px] sm:px-4 md:px-2 md:py-1 md:w-[60px] md:ml-[44px] border shadow-sm text-sm md:text-sm font-bold rounded-full " style={{color: '#FFBE2A', borderColor:'#FFBE2A'}}
          >
            Submit
          </button>
        </form>
      ) : (
        <div className="md:-mt-10">
          <div className="flex justify-center" style={{color: '#FFBE2A'}}>
            <QRCodeCanvas id="qrCode" value={qrCodeValue} />
          </div>
        </div>
      )}
    </div>
  );
};

export default FormComponent;