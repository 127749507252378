import React from 'react'
import img1 from '../image/Frame 72.png'
import img2 from '../image/Frame 73.png'
import img3 from '../image/Frame 74.png'
import img4 from '../image/Frame 75.png'
import img5 from '../image/Frame 76.png'
import img6 from '../image/Frame 77.png'
import img7 from '../image/Frame 78.png'
import img8 from '../image/Frame 79.png'
import img9 from '../image/Frame 80.png'

const Services = () => {
  return (
    <div id='service-section'>
      <div className='md:py-5 py-7'>
        <h1 className='font-bold md:text-4xl text-center text-2xl sm:text-3xl text-[#2C041C]' >Find a perfect Experience</h1>
        <p className='md:px-48 text-center hidden sm:block py-5 sm:px-7 text-[17px] '>SAMRA digital marketing services encompass a comprehensive range of strategies tailored to elevate your online presence and drive business growth. From crafting visually stunning and user-friendly websites to optimizing content for maximum impact, our expertise extends across various domains</p>
        <div className='md:px-20'>
          <div className='lg:grid lg:grid-cols-3 lg:ml-[-165px]  xl:ml-[-95px] 2xl:ml-[10px] ml-8 justify-center ' >
            <div className=' md:ml-28 sm:ml-[120px] sm:w-[340px] lg:w-[320px]  lg:mt-[18px]  px-4 py-4 xl:w-[350px] w-80 rounded-lg bg-[#FFBE2A]'>
              <div>
                <img className='w-12 h-12 sm:w-16 sm:h-16 sm:ml-[130px] ml-[120px] xl:ml-[134px]' src={img1} alt='frame' />
                
              </div>
              <div className='' >
                <p className='font-bold text-center mt-2 text-[18px] '>Website Creation</p>
                <p className='text-center px-2 mt-1 text-[17px] '>We specialize in creating bespoke websites that are visually appealing, easy to navigate, and optimized for conversions</p>
              </div>
            </div>
            <div className=' md:ml-28 px-4 py-4 md:mt-5 mt-5 sm:w-[340px] sm:ml-[120px] lg:w-[320px] lg:ml-[94px] 2xl:ml-[30px] xl:ml-[80px] rounded-lg xl:w-[350px] w-80 bg-[#FFBE2A] '>
              <div>
                <img className='w-12 h-12 sm:w-16 sm:h-16 sm:ml-[130px] ml-[120px] xl:ml-[134px] ' src={img2} alt='frame' />
                
              </div>
              <div className=''  >
                <p className='font-bold text-center mt-2 md:px-16 text-[18px] ' >Content & Affiliate Marketing</p>
                <p className='text-center mt-1 px-2 text-[17px]' >Harnessing the power of compelling content, we drive engagement and conversions through strategic content marketing initiatives. Additionally, our affiliate marketing programs help expand your reach and boost revenue.</p>
              </div>
            </div>
            <div className='px-4 py-4 sm:ml-[120px] sm:w-[340px] md:ml-28 rounded-lg xl:ml-[47px] 2xl:ml-[-50px] lg:w-[320px] lg:ml-[77px] md:mt-5 mt-5 xl:w-[350px] w-80 bg-[#FFBE2A] ' >
              <div>
                <img className='w-12 h-12 sm:w-16 sm:h-16 sm:ml-[130px] ml-[120px] xl:ml-[134px]'  src={img3} alt='frame' />
              </div>
              <div className='' >
                <p className='font-bold text-center mt-2 text-[18px] ' >Social Media Marketing</p>
                <p className='text-center px-2 mt-1 text-[17px] ' >Leveraging popular social media platforms, we craft tailored strategies to enhance brand visibility, engage audiences, and foster meaningful connections with your target demographic.</p>
              </div>
            </div>
          </div>
          <div className='lg:grid lg:grid-cols-3  lg:ml-[-165px] xl:ml-[-95px] 2xl:ml-[10px] lg:-mt-5 ml-8 md:py-7' >
            <div className=' md:ml-28 sm:ml-[120px] px-4 py-4 sm:w-[340px] xl:w-[350px] lg:mt-[18px] w-80 lg:w-[320px] md:mt-0 mt-5 rounded-lg bg-[#FFBE2A]' >
              <div>
                <img className='w-12 h-12 sm:w-16 sm:h-16 sm:ml-[130px] ml-[120px] xl:ml-[134px]'  src={img4} alt='frame' />
                
              </div>
              <div className='' >
                <p className='font-bold text-center text-[18px]' >Email Marketing</p>
                <p className='text-center px-2 mt-1 text-[17px]'>Our targeted email campaigns deliver personalized messaging to nurture leads, drive sales, and foster customer loyalty.</p>
              </div>
            </div>
            <div className=' md:ml-28 sm:ml-[120px] sm:w-[340px] px-4 py-4 xl:w-[350px] w-80 md:mt-5 xl:ml-[80px] 2xl:ml-[30px] lg:ml-[94px] lg:w-[320px] mt-5 rounded-lg bg-[#FFBE2A]'>
              <div>
                <img className='w-12 h-12 sm:w-16 sm:h-16 sm:ml-[130px] ml-[120px] xl:ml-[134px]' src={img5} alt='frame' />
                
              </div>
              <div className=''  >
                <p className='font-bold text-center text-[18px]' >Search Engine Optimization (SEO)</p>
                <p className='text-center px-2 mt-1 text-[17px]'>We employ cutting-edge SEO techniques to improve your website's visibility on search engine results pages, driving organic traffic and maximizing your online reach.</p>
              </div>
            </div>
            <div className=' xl:ml-[47px] sm:ml-[120px] sm:w-[340px] px-4 py-4 xl:w-[350px] lg:w-[320px] lg:ml-[77px] 2xl:ml-[-50px] md:ml-28 w-80 md:mt-5 mt-5 rounded-lg bg-[#FFBE2A]'>
              <div>
                <img className='w-12 h-12  sm:w-16 sm:h-16 sm:ml-[130px] ml-[120px] xl:ml-[134px]'  src={img6} alt='frame' />
                
              </div>
              <div className='' >
                <p className='font-bold text-center text-[18px]' >E-commerce Solutions</p>
                <p className='text-center px-2 mt-1 text-[17px] ' >Whether you're launching an online store or optimizing an existing e-commerce platform, our solutions are designed to streamline the shopping experience and drive conversions.</p>
              </div>
            </div>
          </div>
          <div className='lg:grid lg:grid-cols-3  lg:ml-[-165px] xl:ml-[-95px] 2xl:ml-[10px] ml-8 md:mt-[-22px] ' >
            <div className=' md:ml-28 sm:ml-[120px] sm:w-[340px] px-4 py-4 xl:w-[350px] lg:w-[320px] w-80 md:mt-5 lg:mt-[18px] mt-5 rounded-lg bg-[#FFBE2A]' >
              <div>
                <img className='w-12 h-12 sm:w-16 sm:h-16 sm:ml-[130px] ml-[120px] xl:ml-[134px]' src={img7} alt='frame' />
                
              </div>
              <div className='' >
                <p className='font-bold text-center mt-1 text-[18px]'>Business Development Strategy & Planning</p>
                <p className='text-center px-2 mt-1 text-[17px]'>We collaborate closely with clients to develop robust business strategies aligned with their goals, ensuring sustainable growth and success.</p>
              </div>
            </div>
            <div className=' md:ml-28 sm:ml-[120px] sm:w-[340px] px-4 py-4 xl:w-[350px] lg:w-[320px] xl:ml-[80px] lg:ml-[94px] 2xl:ml-[30px] w-80 md:mt-5 mt-5 rounded-lg bg-[#FFBE2A]' >
              <div>
                <img className='w-12 h-12 sm:w-16 sm:h-16 sm:ml-[130px] ml-[120px] xl:ml-[134px]' src={img8} alt='frame' />
              </div>
              <div className=''  >
                <p className='font-bold text-center mt-1 text-[18px]' >Mobile Marketing</p>
                <p className='text-center px-2 mt-1 text-[17px]'>With the increasing prevalence of mobile devices, our mobile marketing strategies enable you to connect with customers on the go, driving engagement and conversions.</p>
              </div>
            </div>
            <div className=' xl:ml-[47px] sm:ml-[120px] md:ml-28 sm:w-[340px] xl:w-[350px] lg:w-[320px] lg:ml-[77px] 2xl:ml-[-50px] w-80 md:mt-5 mt-5 px-4 py-4 rounded-lg bg-[#FFBE2A]'>
              <div>
                <img className='w-12 h-12 sm:w-16 sm:h-16 sm:ml-[130px] ml-[120px] xl:ml-[134px]' src={img9} alt='frame' />
              </div>
              <div className='' >
                <p className='font-bold text-center mt-1 text-[18px] '>Brand Building</p>
                <p className='text-center px-2 mt-1 text-[17px] ' >Through cohesive branding initiatives, we help cultivate a strong brand identity, build brand equity, and foster trust and loyalty among your target audience.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services