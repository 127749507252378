import React, { useState } from 'react'
import { Link as ScrollLink } from 'react-scroll';
import img1 from '../image/Logo.png'
import img2 from '../image/Vector 4.png'
import img3 from '../image/Vector 5.png'
import img4 from '../image/Vector 6.png'
import img5 from '../image/Vector 7.png'
import img6 from '../image/Vectro (1).png'
import img7 from '../image/Vectro (2).png'
import img8 from '../image/house.png'
import FormComponent from './FormComponent';



const Footer = () => {
  const [activeSection, setActiveSection] = useState(null);
  const handleSetActive = (section) => {
    setActiveSection(section);
  };

  return (
    <div className=' w-full bg-[#2C041C]' >
      <div className=' md:px-8  md:grid grid-cols-4'>
        <div className='mt-4 lg:mt-28 xl:ml-[55px] 2xl:ml-[200px] hidden md:block lg:block  sm:hidden '>
          <img className='md:w-[56px] md:h-[60px] lg:w-[70px] lg:h-[80px] xl:w-[75px] xl:ml-[10px] md:ml-6 w-16 h-16 ' src={img1} alt='logo' />
          <p className='font-bold text-[#FFBE2A] text-[12px] flex'>SAMRA DIGIMECH <span className='md:-mt-2'>&reg;</span></p>
        </div>

        <div className=' flex md:hidden  '>
          <div className='ml-2 mt-4'>
            <img className=' ml-8 w-[46px] h-[50px] sm:w-[57px] sm:h-[60px] sm:mt-4 sm:ml-[30px]'  src={img1} alt='logo' />
            <p className='font-bold flex ml-3 text-[#FFBE2A] text-[10px] sm:text-[10px]'>SAMRA DIGIMECH <span className='-mt-1'>&reg;</span></p>
          </div>
          <div className='mt-8 -ml-4 sm:ml-[230px] sm:mt-14'>
            <ScrollLink to="talk" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('talk')} className="border sm:text-[17px]  px-3 ml-44  py-1 rounded-full" style={{ borderColor: '#FFBE2A', color: '#FFBE2A' }} >Let's Talk</ScrollLink>
          </div>
        </div>
        <p className=' md:hidden  sm:text-[21px] text-center text-[#FFBE2A]'>We expertise your expectations</p>
        <div>
          <hr className="w-0.5 h-36 lg:ml-[-110px] 2xl:ml-[20px] lg:mt-[100px] hidden   sm:hidden xl:block lg:block 2xl:blocks mt-12 border-[#FFBE2A] border-[1px] " style={{  borderRadius: '21px' }} />
        </div>
        <div className=' md:ml-[-400px] md:mt-7' >
          <div className='md:grid md:grid-cols-2 md:py-20 lg:py-16 text-[#FFBE2A] py-3'>
            <div className='flex  sm:ml-40 ml-24 '>
            <div className='md:ml-[-90px] sm:ml-16'>
              <h1 className='font-bold text-xl  sm:text-[21px] md:text-xl' >Menu</h1>
              <ul>
                <li><ScrollLink to="home" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('home')} className={` landscape:text-[18px] sm:text-[18px] ${activeSection === "home" ? "" : ""} `}>Home</ScrollLink></li>
                <li><ScrollLink to="about" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('about')} className={`landscape:text-[18px] sm:text-[18px] ${activeSection === "about" ? "" : ""} `}>About us</ScrollLink></li>
                <li><ScrollLink to="work" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('work')} className={`landscape:text-[18px] sm:text-[18px] ${activeSection === "work" ? "" : ""} `}>Our Work</ScrollLink></li>
                <li><ScrollLink to="testimonials" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('testimonials')} className={`landscape:text-[18px] sm:text-[18px] ${activeSection === "testimonials" ? "" : ""}`}>Testimonials</ScrollLink></li>
              </ul>
            </div>
            <div className='  ml-7 md:mt-0 xl:ml-[60px] '>
              <h1 className='font-bold text-xl md:text-xl  sm:text-[21px] ' >Others</h1>
              <ul>
                <li><ScrollLink to="team" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('team')} className={` md:text-[18px] sm:text-[18px] ${activeSection === "team" ? "" : ""} `}>Team</ScrollLink></li>
                <li><ScrollLink to="services" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('services')} className={` md:text-[18px] sm:text-[18px] ${activeSection === "services" ? "" : ""}`}>Services</ScrollLink></li>
                <li><ScrollLink to="news" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('news')} className={`md:text-[18px] sm:text-[18px] ${activeSection === "news" ? "" : ""}`}>News</ScrollLink></li>
                <li><ScrollLink to="talk" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('talk')} className="hidden md:text-[18px] sm:hidden md:block lg:block" >Let's Talk</ScrollLink></li>
              </ul>
            </div>
            </div>
            <div className=' md:ml-[-30px] sm:ml-20 sm:mt-3'>
              <ul>
                <li className='font-bold ml-[150px] sm:ml-[220px] md:ml-[65px] lg:ml-[60px] xl:ml-[60px] 2xl:ml-[60px] md:mt-[-12px] text-xl sm:text-[21px]  '>Contacts</li>

                <div className='md:block lg:block xl:block 2xl:block sm:ml-16 sm:flex'>
                <div className='flex md:mt-1 ml-[120px] sm:ml-0   '>
                  <img className='w-5 h-5 md:w-6 md:h-6  mt-1' src={img6} alt='' />
                  <a className=' text-sm ml-1 mt-1  sm:text-[18px]' href='tel:+916383710401'>+916383710401</a>
                </div>
                <div className='flex ml-[90px] sm:ml-0 md:ml-0 mt-1 md:mt-1 '>
                  <img className='w-5 h-5 md:w-6 md:h-6 ' src={img7} alt='' />
                  <a className=' ml-1 md:ml-2 text-sm  sm:text-[18px]' href='mailto:samradigimech@gmail.com'>samradigimech@gmail.com</a>
                </div>
                </div>
                <div className='flex  md:ml-4 ml-16  sm:ml-3 sm:px-12 md:mt-1 mt-1'>
                  <img className='md:w-6 w-5 h-5   md:h-6' src={img8} alt='' />
                  <p className='px-[20px] md:px-0 text-sm md:ml-2 sm:text-[18px] ml-[-9px]'>No 77, Gajalakshmi nagar, 3rd Street, Nolambur, Chennai 95.</p>
                </div>

              </ul>
            </div>
          </div>
        </div>
        
        <div>
          <div className='flex ml-[120px] md:ml-[10px] md:mt-[100px] lg:mt-[80px]  py-3 sm:ml-[265px]'>
            <a href='https://www.instagram.com/samradigimech/'><img className='w-7 h-7 ' src={img2} alt='' /></a>
            <a href='https://www.facebook.com/samradigimech'><img className='w-7 h-7 ml-3' src={img5} alt='' /></a>
            <a href='https://in.pinterest.com/samradigimech/'><img className='w-7 h-7 ml-3' src={img4} alt='' /></a>
            <a href='https://x.com/SamraDigimech'><img className='w-7 h-7 ml-3' src={img3} alt='' /></a>
          </div>
          <FormComponent/>
        </div>
      </div>
      <hr className="md:w-5/6  sm:block md:ml-12 lg:ml-[90px]  2xl:ml-[130px] border-[1px] border-[#FFBE2A]  " style={{ borderRadius: '21px' }} />
      <div>
        <p className="md:ml-40 lg:ml-28 ml-2 py-7 2xl:ml-36 font-semibold md:font-bold sm:ml-36 sm:text-[18px] text-[18px] text-[#FFBE2A] flex"  >&copy; {new Date().getFullYear()} Samra Digimech<span className=' w-[8px] -mt-2 '>&reg;</span>. All rights reserved</p>
      </div>
    </div>
  )
}

export default Footer