import React from 'react';
import { useNavigate } from 'react-router-dom';
import img1 from '../image/offline to online.png';
import img2 from '../image/Frame 66.jpg';
import img3 from '../image/Frame 67.jpg';
import img4 from '../image/Frame 68.jpg';
import img5 from '../image/Frame 69.jpg';
import img6 from '../image/Frame 70.jpg';
import img7 from '../image/Frame 71.jpg';
import img11 from '../image/Work-01.png'
import img12 from '../image/Work-02.png'
import img14 from '../image/Work-03.png'
import img8 from '../image/Work-04.png'
import img9 from '../image/Work-05.png'
import img10 from '../image/Work-06.png'
import img13 from '../image/Work-07.png'
import Faq from './Faq';

const Work = () => {
  const navigate = useNavigate();

  const handleImageClick = (imageName) => {
    // Directly use the imageName to calculate the category number
    // Since the image names are sequential and start from img1, subtract 1 from the imageName to get the correct category number
    const categoryNumber = parseInt(imageName.slice(-1), 10); // Get the last character of the imageName and parse it to an integer
    navigate(`/imagecategory${categoryNumber}/${imageName}`);
  };

  return (
    <div id='work-section'>
      <div className=' md:px-16 py-10'>
        <h1 className='font-bold lg:text-4xl md:mt-0 -mt-12 text-2xl text-[#2C041C] text-center'>Work We Do</h1>
        <div className='lg:grid lg:grid-cols-3 md:grid-cols-3 lg:ml-4 md:mt-8'>
          <div className='landscape:grid landscape:grid-rows-4'>
        <div className='md:flex lg:hidden xl:hidden 2xl:hidden md:-ml-12 sm:ml-5'>
        <img className='cursor-pointer py-3 mt-4 ml-4  h-[230px] w-[350px]  md:h-[220px] md:w-[320px] sm:w-[450px] sm:h-[260px] sm:ml-[45px]'  src={img8} alt='frame' onClick={() => handleImageClick('img1')}/>
        <img className=' py-3 cursor-pointer ml-4 md:mt-4 md:ml-2 h-[230px] w-[350px] md:h-[220px] md:w-[320px] sm:w-[450px] sm:h-[260px] sm:ml-[45px]' src={img9} alt='frame' onClick={() => handleImageClick('img2')} />    
        </div>
        <div className='md:flex lg:hidden xl:hidden 2xl:hidden md:-ml-12 sm:ml-5'>
        <img className=' py-3 cursor-pointer ml-4 h-[230px] w-[350px] sm:w-[450px] sm:h-[260px] sm:ml-[45px] md:h-[220px] md:w-[320px]'  src={img11} alt='frame' onClick={() => handleImageClick('img4')} />
        <img className=' py-3 cursor-pointer ml-4 h-[230px] w-[350px] sm:w-[450px] sm:h-[260px] sm:ml-[45px] md:h-[220px] md:ml-2 md:w-[320px]'  src={img10} alt='frame' onClick={() => handleImageClick('img3')} />
        </div>
        <div className='md:flex lg:hidden xl:hidden 2xl:hidden md:-ml-12 md:-mt-3 sm:ml-5'>
        <img className='cursor-pointer py-3  ml-4 h-[230px] w-[350px] sm:w-[450px] sm:h-[260px] sm:ml-[45px] md:h-[220px] md:w-[320px]' src={img14} alt='frame' onClick={() => handleImageClick('img7')} />
        <img className='cursor-pointer py-3  ml-4 h-[230px] w-[350px] sm:w-[450px] sm:h-[260px] sm:ml-[45px] md:h-[220px] md:w-[320px] md:ml-2'  src={img13}alt='frame' onClick={() => handleImageClick('img6')} />
        </div>
        <img className=' py-3 lg:hidden xl:hidden 2xl:hidden cursor-pointer ml-4 md:ml-[160px] md:-mt-6 h-[230px] w-[350px] sm:w-[450px] sm:h-[260px] sm:ml-[65px] md:h-[220px] md:w-[320px]'src={img12} alt='frame' onClick={() => handleImageClick('img5')} />
        </div>
           
          
          <img className=' cursor-pointer hidden lg:block xl:block 2xl:block lg:ml-[-300px] lg:w-[360px] lg:h-[210px] xl:w-[345px] xl:ml-[-320px] 2xl:ml-[-330px] 2xl:h-[230px] ' src={img1}   alt='frame' onClick={() => handleImageClick('img1')} />
          <img className=' cursor-pointer hidden lg:block xl:block 2xl:block lg:w-[280px] lg:h-[170px] lg:ml-[-293px] xl:ml-[-355px] xl:w-[330px] 2xl:ml-[-380px] 2xl:h-[200px]' src={img2} alt='frame' onClick={() => handleImageClick('img2')} />
          <img className=' cursor-pointer hidden lg:block xl:block 2xl:block lg:w-[412px]  lg:h-[408px] lg:ml-[585px] lg:mt-[-210px] xl:ml-[766px] xl:w-[260px] 2xl:ml-[900px] 2xl:w-[300px] 2xl:h-[460px] 2xl:mt-[-230px] ' src={img4} alt='frame' onClick={() => handleImageClick('img4')} />
        </div>
        <div className=' md:ml-4 grid-cols-2 hidden lg:block xl:block 2xl:block xl:ml-[75px] xl:mt-[-10px] 2xl:ml-[220px]  '>
          <img className='  cursor-pointer  lg:w-[295px] lg:h-[190px] lg:mt-[-187px] lg:ml-[-8px] xl:w-[360px] xl:ml-[10px] 2xl:h-[220px] 2xl:mt-[-220px]'  src={img3} alt='frame' onClick={() => handleImageClick('img3')} />
          <img className=' lg:ml-[295px]  cursor-pointer lg:w-[280px] lg:h-[228px] mt-[-230px] xl:ml-[380px] xl:w-[318px] 2xl:h-[245px] 2xl:mt-[-244px] 2xl:w-[325px]'  src={img5} alt='frame' onClick={() => handleImageClick('img5')} />
        </div>
        <div className=' py-3 flex  xl:ml-[80px] 2xl:ml-[183px]'>
          <img className=' cursor-pointer hidden lg:block xl:block 2xl:block lg:w-[436px] lg:h-[225px] lg:ml-[8px] xl:w-[470px] 2xl:w-[505px] 2xl:h-[245px]' src={img7} alt='frame' onClick={() => handleImageClick('img7')} />
          <img className='  cursor-pointer hidden lg:block xl:block 2xl:block lg:w-[436px] lg:h-[225px]  lg:ml-[14px] xl:w-[470px] 2xl:w-[506px] 2xl:h-[245px] ' src={img6} alt='frame' onClick={() => handleImageClick('img6')} />
        </div>
        <Faq />
      </div>
    </div>
  );
};

export default Work;