import React, { useState } from 'react';
import Slider from 'react-slick';
import { PrevArrow, NextArrow } from './Arrow'; // Adjust path as per your file structure
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from '../image/Frame 72.png'
import img2 from '../image/Frame 73.png'
import img3 from '../image/Frame 74.png'
import img4 from '../image/Frame 3.png'
import img5 from '../image/Frame 4.png'
import img6 from '../image/Frame 5.png'

const Carousels = () => {
    const [isSlide1Expanded, setIsSlide1Expanded] = useState(false);
    const [isSlide2Expanded, setIsSlide2Expanded] = useState(false);
    const [isSlide3Expanded, setIsSlide3Expanded] = useState(false);
    const [isSlide4Expanded, setIsSlide4Expanded] = useState(false);
    const [isSlide5Expanded, setIsSlide5Expanded] = useState(false);
    const [isSlide6Expanded, setIsSlide6Expanded] = useState(false);


    const toggleSlide1 = () => setIsSlide1Expanded(!isSlide1Expanded);
    const toggleSlide2 = () => setIsSlide2Expanded(!isSlide2Expanded);
    const toggleSlide3 = () => setIsSlide3Expanded(!isSlide3Expanded);
    const toggleSlide4 = () => setIsSlide4Expanded(!isSlide4Expanded);
    const toggleSlide5 = () => setIsSlide5Expanded(!isSlide5Expanded);
    const toggleSlide6 = () => setIsSlide6Expanded(!isSlide6Expanded);


    const fullContent1 = "Digital marketing is more than just a career; it's a journey of constant learning and adaptation. The digital landscape is ever-evolving, with new tools, platforms, and strategies emerging regularly. Embrace this change and see it as an opportunity to grow and innovate. Remember, every challenge you face is a stepping stone to success. By staying curious and committed, you can turn data into insights, and insights into impactful campaigns. Celebrate small victories and learn from setbacks. Your creativity and resilience are your greatest assets. In the vast world of digital marketing, the only limit is your imagination. Stay motivated, keep pushing boundaries, and watch your efforts transform into tangible results. ";
    const truncatedContent1 = fullContent1.length > 150 ? fullContent1.substring(0, 150) + '...' : fullContent1;

    const fullContent2 = `In recent news, Augmented Reality (AR) has taken a significant leap forward with advancements that promise to revolutionize various industries. One notable development is the integration of AR technology into wearable devices, such as smart glasses and headsets, making immersive experiences more accessible than ever before 👨‍🔬 . Companies like Meta, Microsoft, and Google are leading the charge with innovative AR hardware and software solutions.Moreover, AR is making waves in fields like healthcare 💊 , where it is being used for surgical planning, medical training, and patient education. In education, AR-enhanced learning experiences are transforming classrooms by providing interactive and engaging content 📲 . Additionally, AR is reshaping retail, allowing customers to virtually try on products and visualize them in real-world environments.These recent developments underscore the immense potential of AR to redefine how we interact with technology and the world around us 🌎 . As AR continues to evolve, businesses and industries must stay abreast of these advancements to leverage the full potential of this transformative technology 🕹 .like 2`;
    const truncatedContent2 = fullContent2.length > 150 ? fullContent2.substring(0, 150) + '...' : fullContent2;

    const fullContent3 = `In today's digital landscape, businesses have a plethora of marketing strategies at their disposal. Two primary methods, paid 💵 and organic marketing 🌱 , stand out for their unique approaches and benefits.Paid marketing involves investing resources into targeted advertising, sponsored content, and promotions to reach specific audiences quickly 🎯 . It provides instant visibility and can generate immediate results, making it ideal for short-term campaigns 💻 or promotions.On the other hand, organic marketing focuses on building genuine connections 🤗 and fostering engagement through high-quality content, community-building, and search engine optimization (SEO) 📈 . While it may take time to see significant results, organic marketing cultivates long-term brand loyalty and trust among audiences 👨‍👩‍👧‍👧 .To maximize marketing efforts, businesses should integrate both paid and organic strategies. By combining the immediate impact of paid advertising with the sustainable growth of organic tactics, brands can achieve a balanced and effective digital marketing 📱 approach tailored to their goals and objectives.hashtag`;
    const truncatedContent3 = fullContent3.length > 150 ? fullContent3.substring(0, 150) + '...' : fullContent3;

    const fullContent4 = `Engagement on social media is crucial for businesses looking to build a strong online presence 📲 . It goes beyond just accumulating followers; it’s about fostering meaningful interactions 🗣 with your audience. High engagement rates—likes, comments, 💬 shares, and direct messages—indicate that your content resonates with your audience, helping to build trust 🤝 and loyalty.Engaging content encourages user participation, which can amplify your reach as engaged users share your content with their networks. This organic ☘ spread enhances brand visibility and can attract new followers 👱‍♂️ . Moreover, social media platforms’ algorithms favour content with high engagement, boosting 📈 your content’s visibility. By prioritising engagement, businesses can create a dynamic community, increase brand awareness, and drive more conversions 📊 . `;
    const truncatedContent4 = fullContent4.length > 150 ? fullContent4.substring(0, 150) + '...' : fullContent4;

    const fullContent5 = `Marketing vs. Advertising: It's more than a battle of words; it's a strategic dance of engagement and visibility. Let Samra Digimech guide you through the nuances of both to elevate your brand's presence and drive real results. `;
    const truncatedContent5 = fullContent5.length > 150 ? fullContent5.substring(0, 150) + '...' : fullContent5;

    const fullContent6 = `In the fast-paced world of social media, consistently generating fresh and engaging content is key to capturing audience attention 👭 and driving engagement. Here are some tips to help you spark 💥 creativity and generate content ideas for your social media channels 📌 :

1. Know Your Audience: Understand your target audience's interests, preferences, and pain points 🎯 to create content that resonates with them.

2. Stay Trendy: Keep an eye on industry trends, current events, and popular topics to capitalize 💰 on relevant content opportunities.

3. Repurpose Content: Repurpose existing content into different formats such as videos 🎬 , infographics, or blog posts to maximize reach 📈 and engagement.

4. User-Generated Content: Encourage your audience to create and share content 📥 related to your brand or products, showcasing authentic user experiences.

5. Behind-the-Scenes: Give your audience a glimpse behind the scenes of your business or organization to humanize your brand 🤝 and build trust.

6. Interactive Content: Create polls, quizzes, or interactive posts 🤔 to encourage audience participation and feedback.

By incorporating these strategies into your content planning process, you can keep your social media channels fresh, engaging, and relevant, driving audience engagement and building brand loyalty in the process 💯 .
`;
    const truncatedContent6 = fullContent6.length > 150 ? fullContent6.substring(0, 150) + '...' : fullContent6;

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        customPaging: i => <div className="w-full h-full" />
    };


    return (
        <div className="w-full mx-auto py-20 relative lg:block xl:block 2xl:block ">
            <Slider {...settings}>
            <div className="text-center">
                    <div className='grid grid-cols-2'>
                        <div className=' rounded-md bg-white lg:ml-[110px] lg:w-[370px] xl:w-[450px] xl:ml-[160px]'>
                            <img className='w-56 mt-7 lg:ml-[80px] xl:ml-[110px] h-40  ' src={img3} alt='' />
                            <h1 className='font-bold' style={{ fontSize: '20px' }}>Marketing vs. Advertising</h1>
                            <p className='px-12 py-2'>{isSlide5Expanded ? fullContent5 : truncatedContent5}
                                <img className='w-96' src={img5} alt='frame' />
                            </p>
                            <button className=' underline text-blue-400  py-3 xl:-ml-64 lg:-ml-44' onClick={toggleSlide5}>{isSlide5Expanded ? "Read Less" : "Read More"}</button>
                        </div>
                        <div className=' rounded-md bg-white  lg:ml-[25px] lg:w-[370px] xl:w-[450px] xl:ml-[40px]'>
                            <img className='w-56 mt-7 ml-28 h-40 lg:ml-[80px] xl:ml-[110px]' src={img2} alt='' />
                            <h1 className='font-bold' style={{ fontSize: '20px' }}>Unlocking Creativity</h1>
                            <p className='px-12 py-2'>{isSlide6Expanded ? fullContent6 : truncatedContent6}
                                <img className='w-96' src={img6} alt='frame' />
                            </p>
                            <button className=' underline text-blue-400  py-3 xl:-ml-64  lg:-ml-44 ' onClick={toggleSlide6}>{isSlide6Expanded ? "Read Less" : "Read More"}</button>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <div className='grid grid-cols-2'>
                        <div className='rounded-md bg-white lg:ml-[110px] lg:w-[370px] xl:w-[450px]  xl:ml-[160px]'>
                            <img className='w-56 mt-7 ml-28 h-40 lg:ml-[80px] xl:ml-[110px]' src={img2} alt='' />
                            <h1 className='font-bold' style={{ fontSize: '20px' }}>Engagement on social media</h1>
                            <p className='px-12 py-2'>{isSlide4Expanded ? fullContent4 : truncatedContent4}
                                <img className='w-96' src={img4} alt='frame' />
                            </p>
                            <button className=' underline text-blue-400 xl:-ml-64   lg:-ml-44 py-3' onClick={toggleSlide4}>{isSlide4Expanded ? "Read Less" : "Read More"}</button>
                        </div>
                        <div className='rounded-md  bg-white lg:ml-[25px] lg:w-[370px] xl:w-[450px] xl:ml-[40px]'>
                            <img className='w-56 mt-7 ml-28 h-40 lg:ml-[80px] xl:ml-[110px]' src={img3} alt='' />
                            <h1 className='font-bold' style={{ fontSize: '20px' }}>Organic Marketing</h1>
                            <p className='px-12 py-2'>{isSlide3Expanded ? fullContent3 : truncatedContent3}</p>
                            <button className=' underline text-blue-400  py-3 xl:-ml-64  lg:-ml-44 lg:mt-44' onClick={toggleSlide3}>{isSlide3Expanded ? "Read Less" : "Read More"}</button>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <div className='grid grid-cols-2'>
                        <div className=' rounded-md bg-white  py-5 lg:ml-[110px] lg:w-[370px] xl:w-[450px] xl:ml-[160px] '>
                            <img className='w-56 mt-7 ml-28 h-40 lg:ml-[80px] xl:ml-[110px]' src={img1} alt='' />
                            <h1 className='font-bold' style={{ fontSize: '20px' }}>Digital Marketing</h1>
                            <p className='px-12 py-2'>{isSlide1Expanded ? fullContent1 : truncatedContent1}</p>
                            <button className='underline text-blue-400 xl:-ml-64  lg:-ml-44 lg:mt-6 py-3' onClick={toggleSlide1}>{isSlide1Expanded ? "Read Less" : "Read More"}</button>
                        </div>
                        <div className='rounded-md bg-white py-5 lg:ml-[25px] lg:w-[370px] xl:w-[450px] xl:ml-[40px]'>
                            <img className='w-56 mt-7 ml-28 h-40 lg:ml-[80px] xl:ml-[110px]' src={img2} alt='' />
                            <h1 className='font-bold' style={{ fontSize: '20px' }}>Augmented Reality</h1>
                            <p className='px-12 py-2'>{isSlide2Expanded ? fullContent2 : truncatedContent2}</p>
                            <button className=' underline text-blue-400 xl:-ml-64  py-3 lg:-ml-44 ' onClick={toggleSlide2}>{isSlide2Expanded ? "Read Less" : "Read More"}</button>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default Carousels;