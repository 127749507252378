import React from 'react'
import Carousel from './Carousel'

const Testimonials = () => {
  return (
    <div id='testimonial-section'>
    <div className='sm:py-5 md:mt-0 -mt-8 '>
      <h1 className='font-bold md:text-4xl text-2xl  sm:text-3xl lg:text-4xl xl:text-4xl   text-[#2C041C]  text-center'>Testimonials</h1>
      <Carousel/>
    </div>
    </div>
  )
}

export default Testimonials