import React from 'react';
import { useLocation } from 'react-router-dom';
import img40 from '../image/Frame 72.png';
import img23 from '../image/Frame 73.png';
import img20 from '../image/Frame 74.png';
import img21 from '../image/Frame 75.png';
import img22 from '../image/Frame 76.png';
import img25 from '../image/Frame 77.png';
import img24 from '../image/Frame 78.png';
import img26 from '../image/Frame 79.png';

const CompanyDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get('name');
  const phone = queryParams.get('phone');
  const email = queryParams.get('email');
  const product = queryParams.get('product');

  const handleDownloadVCard = () => {
    // Dynamically set contact details based on query parameters
    const contactName = name || "Default Name"; // Fallback to default if no name provided
    const contactPhone = phone || "+919384677213"; // Fallback to default if no phone provided
    const contactEmail = email || "contact@visttas.com"; // Fallback to default if no email provided
    const contactProduct = product || "Property Management"; // Fallback to default if no product provided
  
    const vCardData = `
  BEGIN:VCARD
  VERSION:4.0
  FN:${contactName}
  ORG:Visttas
  TITLE:${contactProduct}
  TEL;TYPE=work,voice;VALUE=uri:tel:${contactPhone}
  URL:https://visttas.com/
  EMAIL:${contactEmail}
  ADR;TYPE=work;LABEL="Rajiv Gandhi IT Expy, Tharamani, Chennai, Tamil Nadu 600113":
   ; ; Rajiv Gandhi IT Expy; Tharamani; Chennai; ; 600113; India
  END:VCARD
  `;
    const blob = new Blob([vCardData], { type: 'text/vcard' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${contactName.replace(/\s/g, '')}.vcf`; // Remove spaces from name for filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-2 md:mt-20">
        <div>
          <img className="md:ml-72 hidden sm:block" src={img23} alt="world" style={{ width: '400px', height: '600px' }} />
        </div>
        <img className="w-40 md:hidden h-24 -ml-20" src={img40} alt="" />
        <div className="md:flex border border-gray-800 border-l md:-ml-16 ml-8 w-96 md:w-4/5">
          <div className="rounded-lg p-6 md:w-full">
            <img className="md:w-44 w-32 hidden sm:block h-12 md:ml-36 md:h-20" src={img40} alt="" />
            <div className="md:grid grid-cols-2">
              <div className="flex flex-col py-4 space-y-4">
                <div className="grid grid-cols-2">
                  <div><img className="md:ml-0 ml-16" src={img20} alt="vector" /></div>
                  <div className="p-2 -ml-10 md:-mt-2 mt- md:-ml-16">
                    <p className="font-bold" style={{ color: '#2F5C9F' }}>Company Name</p>
                    <p>Visttas</p>
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div><img className="md:ml-0 ml-16" src={img22} alt="vector" /></div>
                  <div className="p-2 md:-mt-2 -ml-10 md:-ml-16">
                    <p className="font-bold" style={{ color: '#2F5C9F' }}>Company Domain</p>
                    <p>Property Management</p>
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div><img className="md:ml-0 ml-16" src={img24} alt="vector" /></div>
                  <div className="p-2 md:-mt-2 -ml-10 md:-ml-16">
                    <p className="font-bold" style={{ color: '#2F5C9F' }}>Mobile Number</p>
                    <p className="mt-1">+91 9384677213</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:mt-4 space-y-4">
                <div className="grid grid-cols-2 md:py-0 py-2">
                  <div><img className="md:ml-0 ml-16" src={img26} alt="vector" /></div>
                  <div className="md:-ml-20 -ml-10">
                    <p className="font-bold" style={{ color: '#2F5C9F' }}>Website</p>
                    <p><a href="https://visttas.com/">https://visttas.com/</a></p>
                  </div>
                </div>
                <div className="grid grid-cols-2 md:py-0 py-2">
                  <div><img className="mt-3 md:ml-0 ml-16" src={img25} alt="vector" /></div>
                  <div className="md:-ml-20 -ml-10 md:mt-2">
                    <p className="font-bold" style={{ color: '#2F5C9F' }}>Email</p>
                    <p>contact@visttas.com</p>
                  </div>
                </div>
                <div className="grid grid-cols-2">
                  <div><img className="mt-1 md:ml-0 ml-16" src={img21} alt="vector" /></div>
                  <div className="md:-ml-20 -ml-10">
                    <p className="font-bold" style={{ color: '#2F5C9F' }}>Address</p>
                    <p className="mt-1">Rajiv Gandhi IT Expy,<br />Tharamani,<br />Chennai-600113.</p>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="text-black py-2 px-4 md:ml-44 mt-5 ml-28 md:mt-7 rounded-md sm:text-sm md:font-bold" style={{ backgroundColor: '#FBB14B' }}
              onClick={handleDownloadVCard}
            >
              Add to Contact
            </button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;