import React from 'react';
import Slider from 'react-slick';
import { PrevArrow, NextArrow } from './Arrows'; // Adjust path as per your file structure
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from '../image/Ellipse 25.png'

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: dots => (
      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <ul style={{ margin: 0, padding: 0, display: 'flex' }}> 
          {dots.map((dot, index) => (
            <li
              key={index}
              style={{
                width: '30px',
                height: '2px',
                background: dot.props.className === 'slick-active' ? '#FFBE2A' : '#662234',
                margin: '0 5px',
                cursor: 'pointer',
              }}
              onClick={dot.props.onClick}
            />
          ))}
        </ul>
      </div>
    ),
    customPaging: i => <div className="w-full h-full" />
  };

    return (
      <div>
        <div className="w-full mx-auto py-10  relative">
            <Slider {...settings}>
                <div className="text-center">
                    <div className=" rounded-lg p-6 sm:-mt-6">
                        <img className='w-28 h-28 ml-[120px]  lg:ml-[430px] sm:ml-[240px] md:ml-[310px] xl:ml-[540px] 2xl:ml-[670px]  xl:w-36 xl:h-36   ' src={img1} alt='' />
                        <h2 className="text-2xl sm:text-2xl xl:text-3xl text-center font-bold py-3">Benjamin Robert</h2>
                        <p className="text-black  sm:text-xl sm:px-24 2xl:px-36 text-center  text-[17px] px-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Suspendisse faucibu.</p>
                    </div>
                </div>
                <div className="text-center">
                    <div className=" rounded-lg p-6 sm:-mt-6 ">
                        <img className='w-28 h-28 ml-[120px] xl:ml-[540px] sm:ml-[240px] md:ml-[300px]  2xl:ml-[670px] lg:ml-[430px] xl:w-36 xl:h-36 xl:items-center  ' src={img1} alt='' />
                        <h2 className="text-2xl font-bold sm:text-2xl  text-center py-3">John Doe</h2>
                        <p className=" text-black text-[17px] sm:text-xl 2xl:px-36 sm:px-24 text-center  px-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Suspendisse faucibu.</p>
                    </div>
                </div>
                <div className="text-center">
                    <div className="rounded-lg p-6 sm:-mt-6 ">
                        <img className='w-28 h-28 ml-[120px] xl:ml-[540px] lg:ml-[430px] 2xl:ml-[670px] sm:ml-[240px] md:ml-[305px] xl:w-36 xl:h-36 xl:items-center landscape:-mt-4  ' src={img1} alt='' />
                        <h2 className="text-2xl font-bold sm:text-2xl  text-center py-3">Robert</h2>
                        <p className=" text-black text-[17px] 2xl:px-36 sm:text-xl sm:px-24  text-center px-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Suspendisse faucibu.</p>
                    </div>
                </div>
            </Slider>
        </div>
        </div>
    );
};

export default Carousel;