import React from 'react';
import './style.css';
import img2 from '../image/Frame1.png';
import img3 from '../image/Frame 64.png';
import img4 from '../image/Frame 63.png';
import img9 from '../image/Logo1.png';
import img10 from '../image/Logo 2.png';
import img11 from '../image/Logo 3.png';
import img5 from '../image/Logo 4.png';
import img6 from '../image/Logo 5.png';
import img7 from '../image/Logo 6.png';
import img8 from '../image/Logo 7.png';



const About = () => {

  return (
    <div id='about-section'>
<div className=" border hidden xl:block lg:block 2xl:block bg-[#FFBE2A] xl:py-7 lg:py-5 lg:w-[950px] xl:w-[1200px] 2xl:w-[1450px] lg:ml-9 mt-6 py-2 rounded-xl"
        style={{
          boxShadow: " 4px 4px 10.4px 1px rgba(0, 0, 0, 0.10)",
        }}
      >
        <h1
          className="font-bold md:text-4xl text-2xl md:mt-0 mt-4 text-center text-[#2C041C]"
        >
          About us
        </h1>
        <h2
          className="font-bold md:text-2xl text-xl py-2 text-center"
          style={{ color: "#2C041C" }}
        >
          We Expertise Your Expectations
        </h2>
        <p className="md:px-20 px-10 font-semibold text-black text-center">
          Digital marketing is an essential part of modern business and helps
          companies of all sizes to connect with customers, build brand
          awareness, and increase sales. Our experienced staff of professionals
          consists of skilled technicians, marketers, managers, designers,
          developers, and social media experts who create innovative and
          creative digital strategies and ideas. We will be best in providing
          consistently successful, unique, and forward-thinking digital
          marketing solutions that take into the individual requirements and
          unique demands of each customer.
        </p>
        <div className="lg:flex py-5">
          <div className="lg:ml-16">
            <div className="lg:flex lg:ml-[-8px] xl:ml-[20px] 2xl:ml-[60px] ">
              <img className="w-12 h-12" src={img2} alt="frame" />
              <p className="font-bold text-xl lg:ml-[4px] lg:mt-3 text-center">
                Our Mission
              </p>
            </div>
            <p className="  text-center mt-2">
              To Promote Products and deliver exceptional Services.
            </p>
          </div>
          <hr
            className="w-0.5 h-40 lg:ml-5   py-2 md:mt-0 -mt-10 border-[#2C041C] border-[1px]"/>
          <div className="lg:ml-10">
            <div className="md:flex lg:ml-16  xl:ml-[130px] 2xl:ml-[200px]">
              <img className="w-12 h-12 " src={img3} alt="frame" />
              <p className="font-bold text-xl lg:mt-3 text-center  lg:ml-2">
                Our Vision
              </p>
            </div>
            <p className=" text-center lg:px-20 lg:ml-[-100px]  mt-2">
              To be a top achiever in online strategies, digital sales, and
              e-mail marketing in the global business world.
            </p>
          </div>
          <hr
            className="w-0.5 h-40   py-2 lg:ml-[-50px] xl:ml-[-55px] border-[#2C041C] border-[1px]"/>
          <div className="xl:ml-5">
            <div className="flex lg:ml-10 xl:ml-24 2xl:ml-32">
              <img className="w-12 h-12 lg:ml-0 " src={img4} alt="frame" />
              <p className="font-bold text-xl lg:mt-3 text-center lg:ml-2">
                Our Goal
              </p>
            </div>
            <p className=" text-center lg:px-4 -ml-7 mt-2 ">
              Grass roots exposure for trending technology to groom you with
              inspirations.
            </p>
          </div>
        </div>
      </div>



      <div className=' bg-[#FFBE2A] w-[350px] sm:w-[500px] md:w-[620px] xl:hidden lg:hidden  ml-5 sm:ml-[70px] md:ml-[80px] py-2 rounded-xl'>
        <h1 className='font-bold lg:text-4xl text-2xl md:text-3xl   md:mt-0 mt-4 text-center text-[#2C041C]'>About us</h1>
        <h2 className='font-bold sm:text-2xl text-xl landscape:text-xl py-2 text-center text-[#2C041C]'>We Expertise Your Expectations</h2>
        <p className=' text-black text-center landscape:text-xl sm:text-2xl px-4'>
          Digital marketing is an essential part of modern business and helps companies of all sizes to connect with customers, build brand awareness, and increase sales. Our experienced staff of professionals consists of skilled technicians, marketers, managers, designers, developers, and social media experts who create innovative and creative digital strategies and ideas. We will be best in providing consistently successful, unique, and forward-thinking digital marketing solutions that take into the individual requirements and unique demands of each customer.
        </p>
        <div className='lg:grid lg:grid-rows-3   py-5'>
          <div className='lg:ml-24'>
            <div className=''>
              <img className='w-12 h-12 sm:w-16 sm:h-16   ml-[150px] sm:ml-[200px] md:ml-[280px] ' src={img2} alt='frame' />
              <p className='font-bold text-xl mt-1 sm:text-2xl text-center'>Our Mission</p>
            </div>
            <p className=' md:px-7 text-center sm:text-2xl '>To Promote Products and deliver exceptional Services.</p>
          </div>
          
          <div className='md:ml-10 mt-2 '>
            <div className=' '>
              <img className='w-12 h-12 sm:w-16 sm:h-16  ml-[150px] sm:ml-[200px] md:ml-[240px] ' src={img3} alt='frame' />
              <p className='font-bold text-xl mt-1 sm:text-2xl md:ml-[-40px] text-center'>Our Vision</p>
            </div>
            <p className=' text-center  sm:text-2xl mt-2 px-3 sm:px-3 '>To be a top achiever in online strategies, digital sales, and e-mail marketing in the global business world.</p>
          </div>
          
          <div className=' mt-2  '>
            <div className=''>
              <img className='w-12 h-12 sm:w-16 sm:h-16 ml-[150px] sm:ml-[200px] md:ml-[280px] ' src={img4} alt='frame' />
              <p className='font-bold text-xl mt-1 sm:text-2xl text-center'>Our Goal</p>
            </div>
            <p className=' text-center px-5  mt-2 sm:text-2xl sm:px-4 md:px-5 '>Grass roots exposure for trending technology to groom you with inspirations.</p>
          </div>
        </div>
      </div>


      <div className=' sm:hidden hidden landscape:block landscape:mt-5 sticky horizontal-scroll '>
        <div className='icon-container'>
          <img className='w-28 h-28 landscape:w-24 landscape:h-24' src={img11 } alt='' />
          <img className='w-28 h-28 landscape:w-24 landscape:h-24 ml-16' src={img10 } alt='' />
          <img className='w-44 h-36 landscape:w-36 landscape:h-28 -mt-2  ml-16' src={img7 } alt='' />
          <img className='w-48 h-32 landscape:w-40 landscape:h-24  ml-16 landscape:ml-12' src={img9 } alt='' />
          <img className='w-28 h-28 landscape:w-24 landscape:h-24 ml-16 mt-4 landscape:mt-0' src={img8 } alt='' />
          <img className='w-36 h-24 landscape:w-28 landscape:h-20 ml-16 mt-5 landscape:mt-2' src={img5 } alt='' />
          <img className='w-44 h-32 landscape:w-32 landscape:h-28 ml-16 landscape:-mt-2' src={img6}  alt=''/>
          </div>
      </div>



      <div className='block landscape:hidden sm:ml-[100px] md:ml-[155px] py-12 px-3 ml-5'>
        <div className='flex'>
          <img className='w-20 h-20 sm:w-28 sm:h-28 md:w-32 md:h-32' src={img11 } alt='' />
          <img className='w-20 h-20 sm:w-28 sm:h-28 md:w-32 md:h-32 ml-12' src={img10 } alt='' />
          <img className='w-32 h-24 sm:w-40 sm:h-32 md:w-44 md:h-[36] -mt-1 ml-5' src={img7 } alt='' />
        </div>
        <div className='flex '>
          <img className='w-28 h-16 mt-8 sm:w-36 sm:h-28 md:w-40 md:h-32 -ml-3' src={img9 } alt='' />
          <img className='w-20 h-20 ml-9 sm:w-28 sm:h-28 md:w-32 md:h-32 sm:mt-8 mt-6' src={img8 } alt='' />
          <img className='w-20 h-12 ml-10 sm:ml-8 sm:w-32 sm:mt-12 sm:h-20 md:w-40 md:h-24 mt-10' src={img5 } alt='' />
        </div>
      </div>
    </div>
  );
};

export default About;