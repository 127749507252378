
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import img2 from "../image/Logo.png";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSetActive = (section) => {
    setActiveSection(section);
  }; 
  

  return (
    <>
      <header className=" w-full h-20 sm:h-24 md:h-24 lg:h-32 xl:h-[130px] 2xl:h-[140px]  fixed top-0 bg-[#2C041E] z-10">
        <div className="flex sm:flex items-center fixed sm:-ml-5">
        <Link to="/" className=" items-center">
            <img src={img2} alt="Logo" className="w-[46px] h-[50px] sm:w-[60px] sm:h-[65px] lg:w-[75px] xl:ml-[60px] lg:h-[80px] mt-3 sm:mt-3 md:mt-3 lg:-mt-2 xl:mt-0   ml-4 lg:ml-10  sm:ml-10 "/>
          </Link>
          <p className='ml-2 mt-3 font-bold text-[9px] sm:text-[15px] lg:mt-[100px] lg:ml-[-5rem] xl:ml-[-5.5rem] lg:text-[12px] text-[#FFBE2A] flex'>SAMRA DIGIMECH<span className='-mt-2 '>&reg;</span></p>
          
          <div className=" md:hidden hidden xl:block lg:block 2xl:block">
            <nav className="font-bold py-4">
              <div className="flex lg:ml-[15rem] xl:ml-[480px] 2xl:ml-[745px] mt-[23px]" >
                <div className="grid md:grid-cols-8 text-[#F09819]">
                <ScrollLink to="/" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('home')} className={`lg:ml-[-140px] ${activeSection === "home" ? "border-b border-current w-[50px]  " : ""} hover:border-b hover:border-current hover:w-[50px]`}>Home</ScrollLink>
                  <ScrollLink to="about" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('about')} className={`lg:ml-[-135px] ${activeSection === "about" ? "border-b border-current " : ""} hover:border-b hover:border-current`}>About us</ScrollLink>
                  <ScrollLink to="work" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('work')} className={`lg:ml-[-110px] ${activeSection === "work" ? "border-b  border-current " : ""} hover:border-b hover:border-current`}>Our Work</ScrollLink>
                  <ScrollLink to="testimonials" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('testimonials')} className={`lg:ml-[-80px] ${activeSection === "testimonials" ? "border-b  border-current " : ""} hover:border-b hover:border-current`}>Testimonials</ScrollLink>
                  <ScrollLink to="team" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('team')} className={`lg:ml-[-30px] ${activeSection === "team" ? "border-b  border-current" : ""} hover:border-b hover:border-current`}>Team</ScrollLink>
                  <ScrollLink to="services" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('services')} className={`lg:ml-[-30px] ${activeSection === "services" ? "border-b  border-current" : ""} hover:border-b hover:border-current`}>Services</ScrollLink>
                  <ScrollLink to="news" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('news')} className={`lg:ml-[-15px] ${activeSection === "news" ? "border-b  border-current" : ""} hover:border-b hover:border-current`}>News</ScrollLink>
                  <ScrollLink to="talk" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('talk')} className="border-2  px-[0.5px] lg:mt-[-5px] w-[80px] font-bold rounded-full py-1 border-[#F09819]" >Let's Talk</ScrollLink>
                </div>
              </div>
            </nav>
          </div>
          <div className=" -ml-5 text-black font-bold" onClick={toggleMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className=" xl:hidden lg:hidden block sm:block md:block text-[#FFBE2A] w-9 h-9 ml-[155px] sm:w-12 sm:h-12 sm:ml-[350px] md:ml-[480px]">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </div>
        </div>
        <nav className={` font-bold mt-12 py-4 bg-[#2C041E] text-[#FFBE2A] ${isMenuOpen ? 'block' : 'hidden'}`} style={{ zIndex: 100 }}>
          <div className="flex flex-col ml-8 text-left">
            <div className="grid grid-rows-7 gap-2 py-3">
              <ScrollLink to="home" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('home')} className={`${activeSection === "home" ? "border-b  border-current" : ""} hover:border-b hover:border-current`}>Home</ScrollLink>
              <ScrollLink to="about" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('about')} className={`${activeSection === "about" ? "border-b  border-current" : ""} hover:border-b hover:border-current`}>About us</ScrollLink>
              <ScrollLink to="work" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('work')} className={`${activeSection === "work" ? "border-b  border-current" : ""} hover:border-b hover:border-current`}>Our Work</ScrollLink>
              <ScrollLink to="testimonials" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('testimonials')} className={`${activeSection === "testimonials" ? "border-b  border-current" : ""} hover:border-b hover:border-current`}>Testimonials</ScrollLink>
              <ScrollLink to="team" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('team')} className={`${activeSection === "team" ? "border-b  border-current" : ""} hover:border-b hover:border-current`}>Team</ScrollLink>
              <ScrollLink to="services" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('services')} className={`${activeSection === "services" ? "border-b border-current" : ""} hover:border-b hover:border-current`}>Services</ScrollLink>
              <ScrollLink to="news" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('news')} className={`${activeSection === "news" ? "border-b  border-current" : ""} hover:border-b hover:border-current`}>News</ScrollLink>
              <ScrollLink to="talk" smooth={true} duration={500} spy={true} onSetActive={() => handleSetActive('talk')}>Let's Talk</ScrollLink>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;