import React from 'react';
import img1 from "../image/Arrow1.png"
import img2 from "../image/Frame 82.png"

const PrevArrow = ({ onClick }) => {
  return (
    <button
      className=" text-black rounded-full absolute top-1/2 left-4 transform -translate-y-1/2 p-2 "
      onClick={onClick}
      aria-label="Previous"
    >
      <img className='w-10 h-10 -mt-28 sm:ml-[100px]  ' src={img1} alt=''/>
    </button>
  );
};

const NextArrow = ({ onClick }) => {
  return (
    <button
      className=" absolute top-1/2 sm:right-32 right-4  rounded-full transform -translate-y-1/2 p-2 " 
      onClick={onClick}
      aria-label="Next"
    >
      <img className='w-10 h-10 sm:ml-[100px] lg:ml-[20px] -mt-28 xl:mt-[-100px] ' src={img2} alt=''/>
    </button>
  );
};

export { PrevArrow, NextArrow };